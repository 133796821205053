import { mappingDataOptions, mappingDataOptionsAddition } from "utilities"

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class ClassroomsApi {
    async getClassrooms(axiosPrivate, params, addition = false) {
        const fetch = await axiosPrivate.get(`${url}/v1/classrooms?direction=ASC&sortBy=grade_grade,name`, { params })
        if(addition){
            return mappingDataOptionsAddition(fetch.data.payload)
        } else{
            return mappingDataOptions(fetch.data.payload)
        }
    }

    async getStudentsByClassroom(axiosPrivate, classroomPublicId, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/classrooms/${classroomPublicId}/students?isActive=true&direction=ASC&sortBy=student_name`, { params })
        return mappingDataOptions(fetch.data.payload)
    }
}

export default new ClassroomsApi()