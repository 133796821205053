/* eslint-disable import/no-anonymous-default-export */
import logo from "../assets/image/logo.png";
import { LihatProfil } from "views/LihatProfil";
import { UbahPassword } from "views/UbahPassword";
import { Beranda } from "views/Beranda";
import { Kesiswaan } from "views/Kesiswaan";
import { Pelanggaran } from "views/Pelanggaran";
import { VerifikasiAkun } from "views/OrangTua";

export default {
  LOGO: logo,
  MENU: [
    {
      name: "Beranda",
      link: "/",
      type: "menu",
      permissions: ["ACHIEVEMENT_R", "VIOLATION_R"],
    },
    {
      name: "Kesiswaan",
      link: "/kesiswaan",
      type: "menu",
      permissions: ["STUDENT_R"],
    },
    {
      name: "Pelanggaran",
      link: "/pelanggaran",
      type: "menu",
      permissions: ["WALI_KELAS"],
    },
    {
      name: "Orang Tua",
      link: "/orang-tua",
      type: "dropdown",
      menu: [
        {
          name: "Verifikasi Akun",
          link: "/orang-tua/verifikasi-akun",
          permissions: ["WALI_KELAS"],
        },
      ],
      permissions: ["WALI_KELAS"],
    },
  ],
  PROFILE_DROPDOWN: [
    {
      name: "Lihat Profil",
      link: "/profil",
    },
    {
      name: "Ubah Password",
      link: "/ubah-password",
    },
    {
      name: "Panduan",
      link: "https://drive.google.com/file/d/1U5OF111YDFq6tLqPl7AcgWak-3iUFm57/view?usp=sharing",
      external: true,
    },
    {
      name: "Keluar",
      link: undefined,
    },
  ],
  ROUTES: [
    {
      path: "/",
      page: <Beranda />,
      name: "Beranda",
      permissions: ["WALI_KELAS", "GURU_BK", "GURU"],
    },
    {
      path: "/beranda",
      page: <Beranda />,
      name: "Beranda",
      permissions: ["WALI_KELAS", "GURU_BK", "GURU"],
    },
    {
      path: "/ubah-password",
      page: <UbahPassword />,
      name: "Ubah Password",
      permissions: [],
    },
    {
      path: "/profil",
      page: <LihatProfil />,
      name: "Profil",
      permissions: [],
    },
    {
      path: "/kesiswaan",
      page: <Kesiswaan />,
      name: "Kesiswaan",
      permissions: ["WALI_KELAS", "GURU_BK"],
    },
    {
      path: "/pelanggaran",
      page: <Pelanggaran />,
      name: "Pelanggaran",
      permissions: ["WALI_KELAS"],
    },
    {
      path: "/orang-tua/verifikasi-akun",
      page: <VerifikasiAkun />,
      name: "Verifikasi Akun Orang Tua",
      permissions: ["WALI_KELAS"],
    },
  ],
};
